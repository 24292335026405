import React from "react";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import { useStripeCheckout } from "../hooks/useStripeCheckout";
import { Section } from "../components/Layout/Section";
import { PageTitle } from "../components/Common/PageTitle";

const TASTER_KIT_PLUS = "prod_R1qWKtPbO3tspS";
const TASTER_KIT = "prod_R1qVvcaMfkTQHZ";

const extraBodyArgs = {
  cancel_url: "https://www.waxcarvers.com/taster-kit",
  success_url: "https://www.waxcarvers.com/taster-kit",
};
const importAll = (r, cache) =>
  r.keys().forEach((key) => (cache[key] = r(key)));
const imageFiles = {};
importAll(
  require.context(`../content/taster-kit/`, true, /\.(png|gif|jpg)$/),
  imageFiles,
);
const transformImageUri = (slug) => (uri) => {
  const imgPath = `./${slug}/${uri.substring(2)}`;
  const importedImg = imageFiles[imgPath];
  return importedImg ? importedImg.default : "";
};

const Spinny = ({ text = "Creating Order" }) => (
  <span className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-blue-700 bg-white hover:bg-indigo-50 sm:w-auto">
    <svg
      className="animate-spin w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z"
        clipRule="evenodd"
      />
    </svg>
    &nbsp;
    {text}
  </span>
);

const Divider = ({ text, margins = {} }) => (
  <div
    className={`relative ${margins.b ? "mb-5" : ""} ${
      margins.t ? "mt-10" : ""
    }`}
  >
    <div className="absolute inset-0 flex items-center" aria-hidden="true">
      <div className="w-full border-t border-gray-300"></div>
    </div>
    <div className="relative flex justify-center">
      <span className="px-3 bg-white text-lg font-medium text-gray-900">
        {text}
      </span>
    </div>
  </div>
);

const TasterKitPrices = () => {
  const {
    loading: loadingTasterKitPlus,
    redirectToCheckout: redirectToTasterKitPlusCheckout,
  } = useStripeCheckout(
    {
      id: TASTER_KIT_PLUS,
    },
    {
      ...extraBodyArgs,
      withShipping: true,
    },
  );

  const {
    loading: loadingTasterKit,
    redirectToCheckout: redirectToTasterKitCheckout,
  } = useStripeCheckout(
    {
      id: TASTER_KIT,
    },
    {
      ...extraBodyArgs,
      withShipping: true,
    },
  );
  return (
    <ul
      role="list"
      className="border border-gray-500 rounded-md divide-y divide-gray-500 my-5"
    >
      <li className="py-3 px-2 flex items-center justify-between text-md flex-col-reverse md:flex-row">
        <div className="sm:ml-3 flex-shrink-0">
          {loadingTasterKit ? (
            <Spinny nomt />
          ) : (
            <div className="flex items-center justify-center">
              <button
                onClick={redirectToTasterKitCheckout}
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent
             text-base font-medium rounded-md text-white bg-blue-700 hover:bg-blue-900"
              >
                Wax Taster Kit €50
              </button>
            </div>
          )}
          <div className="flex items-center justify-center">
            <small>(plus shipping, fees and duties)</small>
          </div>
        </div>

        <div className="flex-1 items-center mb-4">
          <p className="ml-12 flex-1">
            Small sizes of different hard and soft waxes for you to try out!
            &nbsp;
          </p>
        </div>
      </li>
      <li className="pl-3 pr-4 py-3 flex items-center justify-between text-md flex-col-reverse md:flex-row">
        <div className="flex-shrink-0">
          {loadingTasterKitPlus ? (
            <Spinny nomt />
          ) : (
            <div className="flex items-center justify-center">
              <button
                onClick={redirectToTasterKitPlusCheckout}
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent
             text-base font-medium rounded-md text-white bg-blue-700 hover:bg-blue-900"
              >
                Wax Taster Kit + €85
              </button>
            </div>
          )}
          <div className="flex items-center justify-center">
            <small>(plus shipping, fees and duties)</small>
          </div>
        </div>
        <div className="flex flex-col">
          <p className="ml-12">
            Small sizes of different hard and soft waxes for you to try out.{" "}
            <b>Plus</b> a 50 gram block of Mitsuro Hikime wax! &nbsp;
          </p>
        </div>
      </li>
    </ul>
  );
};

const PriceCard = () => {
  return (
    <div
      id="purchase"
      className="bg-blue-100 mt-2 relative max-w-screen-lg mx-auto py-8 px-4 sm:px-6 lg:px-8 rounded-md"
    >
      <div className="max-w-md mx-auto lg:max-w-3xl">
        <dd className="mt-8 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <TasterKitPrices />
        </dd>
      </div>
    </div>
  );
};

const TasterKit = ({ pageContext: { content } }) => {
  const bannerImg =
    "http://images.waxcarvers.com.s3-website-eu-west-1.amazonaws.com/waxcarvers.png";
  const attributes = {
    title: "Wax Carvers Taster Kit",
    description:
      "A box with a big variety of hard and soft carving waxes for you to try out and make jewellery with ",
  };
  return (
    <Layout full>
      <Helmet>
        <title>{attributes.title} ¦ Wax Carvers</title>
        <meta name="description" content={attributes.description} />
        <meta
          property="og:title"
          content={`${attributes.title} | Wax Carvers`}
        />
        <meta property="og:image" content={bannerImg} />
        <meta
          property="og:url"
          content={`https://www.waxcarvers.com/taster-kit`}
        />
        <meta property="og:description" content={attributes.description} />

        <meta itemProp="name" content={`${attributes.title} | Wax Carvers`} />
        <meta itemProp="description" content={attributes.description} />

        <meta
          property="twitter:title"
          content={`${attributes.title} | Wax Carvers`}
        />
        <meta property="twitter:description" content={attributes.description} />
        <meta property="twitter:card" content={bannerImg} />
        <meta property="twitter:image:src" content={bannerImg} />
      </Helmet>
      <div className="bg-white">
        <div className="mt-12 mb-2">
          <PageTitle title={content.a.attributes.title} />
        </div>
        <main>
          <div className="sm:max-w-3xl sm:mx-auto sm:p-6 sm:block lg:p-8">
            <p className="text-xl tracking-wide whitespace-pre-wrap text-center mt-4">
              I love trying new things. I wrote a blog series on{" "}
              <a
                href="/blog/post/what-wax-color-should-I-get"
                className="text-blue-600"
              >
                the different hard wax colors
              </a>{" "}
              just to give myself an excuse to buy and try them all out!
              <img
                src={transformImageUri("a")(content.a.attributes.image)}
                alt="Taster Kit Overview"
                className="h-full w-full object-cover my-12 sm:mx-auto"
              />
              It's a bit much I know. <br /> <br />
              Luckily you don’t have to start your own blog to do the same. You
              can get the wax taster kit!
            </p>
          </div>
          {["b", "c"].map((it, idx) => (
            <div key={it} className="mb-12">
              <Section
                key={it}
                centerTitle={content[it].attributes.title}
                blue={it === "b"}
                item={{
                  orientation: idx % 2 === 0 ? "right" : "left",
                  title: content[it].attributes.title,
                  text: content[it].body,
                  image: transformImageUri(it)(content[it].attributes.image),
                  imageAlt:
                    content[it].attributes.imageAlt ??
                    content[it].attributes.title,
                }}
                transformImageUri={transformImageUri(it)}
                asMarkdown={true}
              />
            </div>
          ))}

          {["d"].map((it, idx) => (
            <div key={it} className="py-12 bg-blue-100">
              <Section
                blue
                item={{
                  orientation: idx % 2 === 0 ? "right" : "left",
                  title: content[it].attributes.title,
                  text: content[it].body,
                  image: transformImageUri(it)(content[it].attributes.image),
                  imageAlt:
                    content[it].attributes.imageAlt ??
                    content[it].attributes.title,
                }}
                transformImageUri={transformImageUri(it)}
                asMarkdown={true}
              />
            </div>
          ))}

          <div className="bg-blue-600">
            <div className="max-w-4xl mx-auto py-6 px-4 sm:px-4 lg:px-4">
              <p className="text-xl text-white whitespace-pre-wrap text-center mt-12 mb-6">
                <ReactMarkdown>{content.g.body}</ReactMarkdown>
              </p>
            </div>
          </div>

          <div className="sm:hidden mx-auto sm:p-6">
            <img
              src={transformImageUri("a")(content.j.attributes.image)}
              alt="Taster Kit Overview"
              className="h-full w-full object-cover"
            />
          </div>

          <div className="hidden sm:block md:mt-4">
            <div className="max-w-4xl mx-auto px-4 sm:px-4 lg:px-4">
              <img
                src={transformImageUri("a")(content.j.attributes.image)}
                alt="Taster Kit Overview"
                className="h-full w-full object-cover"
              />
            </div>
          </div>

          <Divider margins={{ t: true, b: true }} />
          <div className="text-center py-4">
            <h2 className="text-3xl tracking-tight text-blue-700 mx-auto">
              Yes I want to try all those different waxes!
            </h2>
          </div>
          <PriceCard />
          <div className="text-gray-900 max-w-screen-lg text-md rounded-b-lg px-6 py-4 sm:px-10 sm:py-4 text-center mx-auto">
            <p>
              If your country isn’t listed as a shipping destination, email
              me:&nbsp;
              <a href="mailto:info@waxcarvers.com">info@waxcarvers.com</a>. I
              have to add all the countries manually! <br /> <br />
              I'm away for a big chunk of February, first shipping date of a
              taster kit is February 28!
              <br />
            </p>
          </div>

          <section>
            <div className="bg-blue-100 lg:py-5 rounded mt-12">
              <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-center">
                <h2 className="text-2xl text-bold tracking-tight text-gray-700 text-center">
                  <span className="block">Always know what I'm up to!</span>
                </h2>
                <div className="lg:mt-0 lg:flex-shrink-0 mx-12 mt-8">
                  <div className="rounded-md text-center">
                    <a
                      href="https://newsletter.waxcarvers.com"
                      aria-label="Join newsletter"
                      className="inline-flex items-center justify-center px-5 py-3 border border-transparent
                         text-base font-medium rounded-md text-white bg-blue-700 hover:bg-blue-900"
                    >
                      Get weekly emails!
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </Layout>
  );
};

export default TasterKit;
